export default {
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      tipoAcaoResource: this.$api.tipoAcao(this.$resource),
      divisaoResource: this.$api.divisao(this.$resource),

      camposFormulario: {
        padrao: [
          {
            labelCampo: 'data_inicio',
            tipoCampo: 'DATA',
          },
          {
            labelCampo: 'data_fim',
            tipoCampo: 'DATA',
          },
          {
            labelCampo: 'origem',
            nomCampoId: 'origem',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: () => new Promise((resolve) => resolve({
                data: [
                  { texto: `${this.$tc('label.contrato', 1)}`, valor: 'contrato' },
                  { texto: `${this.$tc('label.acao_tatica', 1)}`, valor: 'acao_tatica' },
                ],
              })),
              itemValue: 'valor',
              itemText: 'texto',
            },
          },
          {
            labelCampo: 'tipo_acao',
            nomCampoId: 'id_acao_tipo',
            tipoCampo: 'LISTA',
            async: {
              fetchFunction: (autocomplete) => this.tipoAcaoResource.buscarAtivos({ autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'divisao',
            nomCampoId: 'id_divisao',
            tipoCampo: 'LISTA',
            async: {
              fetchFunction: (autocomplete) => this.divisaoResource.selecao({ autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'unidadenegocio',
            nomCampoId: 'id_unidadenegocio',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResource
                .buscarUnidadesNegocioEntidadesControlador({ autocomplete }),
              itemValue: 'id',
              itemText: 'nomExtensao',
            },
          },
          {
            labelCampo: 'fornecedor',
            nomCampoId: 'id_fornecedor',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResource
                .buscarFornecedores({ autocomplete }),
              itemValue: 'id',
              itemText: 'codNomeCnpj',
            },
          },
          {
            labelCampo: 'numero',
            tipoCampo: 'NUMERICO',
          },
        ],
      },
    };
  },
};
